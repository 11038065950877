// 결제정보에 들어갈 주문상품 테이블 css
#order-table-container {
  border-top: 0.5px solid #f4f0f8;

  th {
    padding: 8px 0;
    border: none;

    &.w-50 {
      width: 50%;
    }
  }

  td {
    padding: 8px 0;
    border: none;
  }
}
