#product-manage-table {
  min-width: 320px;
  position: relative;

  thead {
    background-color: #FAFAFB;
    border-bottom-color: red;
    word-break: keep-all;

    p {
      color: #16171966;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
    }

    th {
      padding: 12px 0;
      border-top: 1.5px solid #eeeeee;
    }

    th:nth-child(1) {
      padding-left: 20px;
      min-width: 160px;
    }
    th:nth-child(2) {
      width: 106px;
      max-width: 106px;
    }
    th:last-child {
      padding-right: 20px;
      width: 65px;
      max-width: 65px;
    }
  }

  tbody {
    td {
      padding: 12px 8px;
    }
    td:nth-child(1) {
      padding-left: 20px;
    }
    td:last-child {
      padding-right: 20px;
    }
  }
}
