@import 'transitions';
@import 'palette';
@import 'kmwine-sweetalert2';

// google font - Inter
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
  user-select: none;

  &.is-bottom-nav-show {
    .WineOne-Footer {
      //padding-bottom: 80px !important;
    }
  }
}

// 상품 이미지
.product-image-wrapper {

  img:first-child {
    object-fit: contain;
    object-position: center center;

    /* drag 막기 */
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}


/*
 * main banner - slick customize
 */
.main-slick-square {
  .slick-list {
    .slick-slide {
      > div {
        max-height: calc(100vw);
      }
    }
  }
}

.no-border-radius {
  border-radius: 0 !important;
}

.none-scroll-bar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* make scrollbar transparent */
  }
}

.is-bottom-nav-show {
  #root {
    margin-bottom: 56px;
  }
}

// 말줄임표 1줄
.line-1-ellipsis {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// 말줄임표 2줄
.line-2-ellipsis {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// outlined btn
.outline-btn {
  border: 1px solid #140229;
}

// 상세페이지 title
.detail-title {
  font-size: 16px;
  font-weight: 600;
  color: #220348;
}

// 상세페이지 content
.detail-content {
  font-size: 16px;
  color: #140229;
}

// 상세페이지 요일 content
.detail-content-days {
  font-size: 16px;
  color: #140229;

  &.holiday {
    color: #ed4c4c;
  }
}

// 홈 [START]
#new-alarm-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f26a2d;
  position: absolute;
  top: 10%;
  right: 24%;
}

#home-order-wait {
  thead {
    tr {
      th {
        text-align: center;
        border: 0;
        font-size: 16px;
        color: #140229;
        font-weight: 500;
        padding: 0;

        &#center-th {
          border-left: 1px solid #dfdfdf;
          border-right: 1px solid #dfdfdf;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        position: relative;
        text-align: center;
        border: 0;
        padding: 5px 5px 0 5px;

        &#center-td {
          border-left: 1px solid #dfdfdf;
          border-right: 1px solid #dfdfdf;
        }

        #wait-count {
          font-size: 36px;
          font-weight: 900;
          color: #47c454;
          margin-right: 3px;
        }

        #exchange-count {
          font-size: 36px;
          font-weight: 900;
          color: #fec466;
          margin-right: 3px;
        }

        #return-count {
          font-size: 36px;
          font-weight: 900;
          color: #fb6464;
          margin-right: 3px;
        }

        #pick-return-count {
          font-size: 36px;
          font-weight: 900;
          color: #fb6464;
          margin-right: 3px;
        }

        span {
          font-size: 16px;
          color: #140229;
          font-weight: 500;
        }
      }
    }
  }
}

.refresh_shake {
  animation: shake 1s infinite;
}

@keyframes shake {
  0% {
    transform: translate(0) rotate(0deg);
  }
  10% {
    transform: translate(0) rotate(36deg);
  }
  20% {
    transform: translate(0) rotate(72deg);
  }
  30% {
    transform: translate(0) rotate(108deg);
  }
  40% {
    transform: translate(0) rotate(144deg);
  }
  50% {
    transform: translate(0) rotate(180deg);
  }
  60% {
    transform: translate(0) rotate(216deg);
  }
  70% {
    transform: translate(0) rotate(252deg);
  }
  80% {
    transform: translate(0) rotate(288deg);
  }
  90% {
    transform: translate(0) rotate(324deg);
  }
  100% {
    transform: translate(0) rotate(360deg);
  }
}
// 홈 [END]

// 주문, 픽업대기 tab [START]
#wait-tabs {
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;

  .MuiTabs-scroller {
    border-bottom: 1px solid #d0cbd7;

    .MuiTabs-flexContainer {
      height: 100%;

      .MuiTab-root {
        width: 33%;
        color: #140229;
        font-size: 16px;
        font-weight: 500;
        padding: 0;

        .MuiGrid-container {
          width: auto;
        }
      }
    }
  }

  .MuiTabs-indicator {
    height: 1px;
  }
}

.shadow-box {
  width: 100%;
  height: auto;
  box-shadow: -3px 2px 6px 0px #00000029;
  border-radius: 12px;
  padding: 16px 10px;
}
// 주문, 픽업대기 tab [END]

// 주문상세, 픽업상세 tab [START]
// TODO 나중에 important된 영역 수정해야함.
.detail-tab-button {
  flex-grow: 1;
  font-size: 16px !important;
  padding: 6px 0 !important;
  box-shadow: none !important;
  background-color: #f4f0f8 !important;
  color: #220348 !important;
  font-weight: 500 !important;
  border: 1px solid #f4f0f8 !important;

  &.select {
    background-color: #ffffff !important;
  }
}

#pickup-product-table {
  th {
    padding: 0;
    border: none;
  }
  td {
    padding: 8px 0;
    border: none;
  }
}

.product-option-list {
  padding-left: 20px;
  padding-top: 4px;
  margin: 0;

  li {
    font-size: 12px;
    color: #3f3f3f;

    &.important {
      color: #fb6464;
    }

    :before {
      content: '!';
    }
  }
}
// 주문상세, 픽업상세 tab [END]

// 입점샵 상세 [START]
#my-info-table {
  tbody {
    tr {
      td {
        border: 0;
        font-size: 16px;
        color: #140229;
        padding: 0;
      }
    }
  }
}

.service-tag-box {
  width: 70px;
  height: 28px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
  border: 0.5px solid #220348;
  font-size: 10px;
  color: #140229;

  &.select {
    background-color: #f4f0f8;
  }
}
// 입점샵 상세 [END]

// 알람 [START]
#alarm-drawer {
  .MuiDrawer-paper {
    height: 100vh;
  }
}
// 알람 [END]

// 매장방문대기 드로어 [START]
#wait-drawer {
  .MuiDrawer-paper {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    height: auto;
    max-height: 70vh;
  }
}
// 매장방문대기 드로어 [END]

// 상품상세 [START]
#detail-drawer {
  .MuiDrawer-paper {
    height: 100vh;
  }
}
// 상품상세 [END]

// 매장방문대기 [START]
#visit-timeline {
  padding: 0;
  margin-top: 0;

  .MuiTimelineItem-root {
    .MuiTimelineSeparator-root {
      padding-left: 4px;
      padding-right: 4px;

      &.select {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
    .MuiTypography-root {
      padding-right: 0;
    }
  }

  li:before {
    content: none;
  }
}
// 매장방문대기 [END]

#quantity-update-field {
  text-align: right;
}

// 공지사항 div내 ul 정렬
#noticeDiv {
  ul {
    padding-inline-start: 20px;
  }
}
