.fade-enter {
  opacity: 0;
  transform: translate(0, 0px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

/*
 * Slide transition
 */
.slide-appear,
.slide-enter {
  position: fixed;
  width: 100vw;
  top: 0;
  min-height: calc(var(--vh, 1vh) * 100);
  z-index: 1201;
  opacity: 0.9;
  overflow: hidden;
  /* transform: scale(1.1); */
  transform: translate3d(100%, 0, 0);
}
.slide-appear-active,
.slide-enter.slide-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 10px 0 rgb(140 152 164 / 75%);
  transition: all 350ms;
}

.slide-exit {
  position: fixed;
  z-index: 1201;
  top: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.slide-exit.slide-exit-active {
  opacity: 0.8;
  box-shadow: 0 0 10px 0 rgb(140 152 164 / 75%);
  transform: translate3d(100%, 0, 0);
  transition: all 350ms;
  //transition: all 60s;
}


.blink_80 {
  animation: blinker_80 3s linear infinite;
}

@keyframes blinker_80 {
  50% {
    opacity: 0.80;
  }
}
